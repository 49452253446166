<template>
    <div class="finance">
        <div class="mine_msg" center_bgc>
            <div class="msg_btm">
                <div class="msg_btm_item level_left">
                    <div class="btm_item_top">
                        <span>付费等级</span>
                        <span class="go_cost" @click="go_recharge">充 值</span>
                    </div>
                    <div @click="go_recharge" :class="info.is_vip ? 'level_2' : 'level'">
                        <i class="iconfont icon-huiyuandengji"></i>
                        <span
                            v-text="info.is_vip ? (info.vip ? info.vip : '暂无') : '暂无'"
                        ></span>
                    </div>
                </div>
                <div class="msg_btm_item">
                    <div class="btm_item_top">
                        <span>账户余额</span>
                        <span class="go_cost" @click="go_recharge">充 值</span>
                    </div>
                    <div :class="Number(info.book_coin) === 0.00 ? 'level' : 'level_1'">
                        <i
                            class="iconfont"
                            :class="Number(info.book_coin) === 0.00 ? 'icon-jinbi1':'icon-tubiaozhizuomoban'"
                        ></i>
                        <span>{{ info.book_coin }}</span
                        >书币
                    </div>
                </div>
            </div>
        </div>
        <el-tabs type="border-card" @tab-click="focus_tab">
            <el-tab-pane label="消费记录">
                <el-table :data="tableData1" style="width: 100%">
                    <el-table-column
                        prop="create_time"
                        label="消费时间"
                    ></el-table-column>
                    <el-table-column prop="name" label="消费类型">
                        <template slot-scope="scope">
                            <p>{{ scope.row.type === 1 ? "书籍消费" : scope.row.type === 2 ? "打赏消费" : "" }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="book_coins"
                        label="消费数量（书币）"
                    ></el-table-column>
                    <el-table-column prop="title" label="消费说明">
                        <template slot-scope="scope">
                            <div>
                                <p>【{{ scope.row.book && scope.row.book.name }}】</p>
                                <p v-if="scope.row.prop">{{ scope.row.prop && scope.row.prop.name }} x {{scope.row.give_prop_num}}</p>
                                <p v-if="scope.row.section">{{ scope.row.section && scope.row.section.title }}</p>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="custome_bottom">
                    <c-page :callBack="get_table_data" :page="page1"></c-page>
                </div>
            </el-tab-pane>
            <el-tab-pane label="充值记录">
                <el-table :data="tableData2" style="width: 100%">
                    <el-table-column
                        prop="pay_money"
                        label="充值金额（元）"
                    ></el-table-column>
                    <el-table-column
                        prop="pay_time"
                        label="充值时间"
                    ></el-table-column>
                    <el-table-column
                        prop="goods_name"
                        label="商品名"
                    ></el-table-column>
                </el-table>
                <div class="chage_bottom">
                    <c-page :callBack="get_table_data" :page="page2"></c-page>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {Recordinfo, Moneyinfo} from "@/https/mine_center.js";

export default {
    name: "",
    components: {},
    data() {
        return {
            page1: {page: 1, limit: 10, total: 0},
            page2: {page: 1, limit: 10, total: 0},
            info: {},
            lev_num: "",
            book_coin: "",
            tableData1: [],
            tableData2: [],
            table_id: 1
        };
    },
    methods: {
        // table_id：[1  消费记录][2  充值记录]
        // 获取数据
        get_table_data() {
            let params = {
                table: this.table_id || 1,
            };
            if (this.table_id === 1) {
                params.page = this.page1.page
                params.limit = this.page1.limit
            } else if (this.table_id === 2) {
                params.page = this.page2.page
                params.limit = this.page2.limit
            }
            Recordinfo(params).then((res) => {
                switch (this.table_id) {
                    case 1:
                        this.page1.total = res.data.total
                        this.tableData1 = res.data.data;
                        break;
                    case 2:
                        this.page2.total = res.data.total
                        this.tableData2 = res.data.data;
                        break;
                }
            });
        },
        // 去充值中心
        go_recharge() {
            let mine_menu = {
                link: "/recharge",
                name: "充值中心",
                id: 7,
                icon: "icon-caiwuzhongxin",
            };
            this.$store.commit("set_mine_menu", mine_menu);
            this.$router.push({
                path: '/recharge'
            })
        },
        // tab聚焦点击时间
        focus_tab(val) {
            if (val.label === "消费记录") {
                this.table_id = 1
                this.get_table_data();
            }
            if (val.label === "充值记录") {
                this.table_id = 2
                this.get_table_data();
            }
        },
        // 获取用户信息
        get_finance_info() {
            Moneyinfo().then((res) => {
                this.info = res.data;
                // this.lev_num = res.data.vip;
                // this.book_coin = res.data.balance;
            });
        },
        // 书籍详情
        book_info(id) {
            const {href} = this.$router.resolve({
                path: '/show_book',
                query: {
                    id: id
                }
            });
            window.open(href, '_blank');
        },
        // 读书界面
        read_book(book_id, page_id) {
            const {href} = this.$router.resolve({
                path: "/read_book",
                query: {
                    id: book_id,
                    page: page_id
                }
            });
            window.open(href, '_blank');
        }
    },
    async mounted() {
        await this.get_finance_info();
        await this.get_table_data();
    },
};
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";
// @include flex-center();
.finance {
    // box-shadow: 0 0 5px #e4e4e4;
}

/deep/ .el-tabs--border-card {
    border: none;
    box-shadow: none;
    border-top: 1px solid #DCDFE6;
}

.mine_msg {
    /deep/ {
        .el-tabs__content {
            min-height: calc(570px - 190px);
        }

        th {
            width: 30%;
        }
    }

    // margin-top: 30px;
    width: 100%;
    // border: 1px solid #e4e4e4;
    // box-shadow: 0 0 5px #e4e4e4;
    @include flex-column();

    .msg_btm {
        @include flex-row();

        .msg_btm_item {
            flex: 1;
            height: 150px;
            @include flex-column-center();

            .btm_item_top {
                span {
                    padding: 0 20px;
                }

                .go_cost {
                    color: #409eff;
                    cursor: pointer;
                    border-left: 1px solid #e4e4e4;
                }
            }

            .level {
                padding-top: 10px;
                font-size: 16px;
                line-height: 50px;
                color: #ccc;
                // > i {
                //   display: inline-block;
                //   width: 20px;
                //   height: 20px;
                // }
                > span {
                    font-size: 16px;
                    font-weight: bold;
                    padding: 5px;
                }

                .iconfont {
                    font-size: 16px;
                }
            }

            .level_1 {
                padding-top: 10px;
                font-size: 14px;
                line-height: 50px;
                color: #fc9c0e;

                > span {
                    font-size: 26px;
                    font-weight: bold;
                    padding: 5px;
                }

                .iconfont {
                    font-size: 22px;
                }
            }

            .level_2 {
                padding-top: 10px;
                font-size: 14px;
                line-height: 50px;

                > i,
                > span {
                    color: #f9993b;
                }

                > span {
                    font-size: 22px;
                    font-weight: bold;
                    padding: 5px;
                }

                .iconfont {
                    font-size: 22px;
                }
            }
        }

        .level_left {
            border-right: 1px solid #e4e4e4;
        }
    }
}

.chapter_title {
    cursor: pointer;
}

.chapter_title:hover {
    color: #409eff;
}

/deep/ .el-tabs__content {
    position: relative;

    .custome_bottom, .chage_bottom {
        width: calc(100% - 30px);

        .cPage {
            width: 100%;
        }
    }

    .el-table {
        max-height: calc(100% - 40px);
        p{
            text-align: center;
        }
    }
}
</style>
